import React, { FC } from 'react';

import Image from 'common/Image';
import ProductWithLogo from 'components/ProductWithLogo/ProductWithLogo';

import { ProductListDesktopProps } from './models';

import './ProductListDesktop.scss';

const ProductListDesktop: FC<ProductListDesktopProps> = ({ items }) => {
  const [selected, setState] = React.useState(0);
  const select = (index) => {
    setState(index);
  };

  return (
    <div className="product-list-desktop">
      <div className="product-list-desktop__selections">
        {items.map(({ buy, imageDesktop, title, description, showMore, storeItem }, index) => (
          <div
            key={title}
            data-testid="product-list-desktop-item"
            className={`product-list-desktop__selections-item ${
              selected === index ? '' : 'd-none'
            }`}
          >
            <Image imageData={imageDesktop} alt={imageDesktop.altText} />
            <div className="product-list-desktop__selections-item-text">
              <h3>{title}</h3>
              <p>{description}</p>
              {showMore ? (
                <a
                  href={showMore.url}
                  className="py-2 mb-3 product__link--green"
                  rel="noreferrer"
                  aria-label={showMore.name}
                >
                  {showMore.name}
                </a>
              ) : null}
              {buy ? (
                <ProductWithLogo {...{ buy, storeItem }} classname="product__link--logo" />
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <div className="product-list-desktop__items d-flex px-4">
        {items.map(({ thumbnail, title }, index) => (
          <div className="product-list-desktop__items-item" key={title}>
            <Image imageData={thumbnail} alt={thumbnail.altText} />
            <button
              type="button"
              className="p-2 border-0 d-flex align-items-center justify-content-center"
              onClick={() => select(index)}
              aria-label={title}
            >
              <h4>{title}</h4>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductListDesktop;
