import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';

import Image from 'common/Image';
import ProductWithLogo from 'components/ProductWithLogo/ProductWithLogo';

import { ProductListMobileProps } from './models';

import './ProductListMobile.scss';

const ProductListMobile: FC<ProductListMobileProps> = ({ items }) => {
  const [selected, setState] = React.useState(0);
  const select = (index) => {
    setState(index);
  };

  return (
    <div className="list-product">
      <div className="list-product__selections">
        {items.map(({ buy, imageMobile, title, showMore, storeItem }, index) => (
          <div
            data-testid="list-product-item"
            key={title}
            className={`list-product__selections-item ${selected === index ? '' : 'd-none'}`}
          >
            <Image imageData={imageMobile} alt={imageMobile.altText} />
            <h3 className="list-product__title">{title}</h3>
            {showMore ? (
              <a
                href={showMore.url}
                className="py-2 product__link product__link--green list-product__buy"
                rel="noreferrer"
                aria-label={showMore.name}
              >
                {showMore.name}
              </a>
            ) : null}
            {buy ? (
              <ProductWithLogo
                buy={buy}
                storeItem={storeItem}
                classname="product__link--logo"
                classnameButton="product__link product__link--button-red"
              />
            ) : null}
          </div>
        ))}
      </div>
      <div className="list-product__items px-4">
        <Carousel
          showThumbs={false}
          showIndicators
          showArrows={false}
          showStatus={false}
          infiniteLoop
        >
          {items.map(({ thumbnail, title }, index) => (
            <div className="list-product__items-item" key={title}>
              <Image imageData={thumbnail} alt={thumbnail.altText} />
              <button
                type="button"
                className="p-0 border-0 d-flex align-items-center justify-content-center"
                onClick={() => select(index)}
                aria-label={title}
              >
                {title}
              </button>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default ProductListMobile;
