import React, { FC } from 'react';

import ProductWithLogoProps from './model';

import './ProductWithLogo.scss';

const ProductWithLogo: FC<ProductWithLogoProps> = ({
  buy,
  storeItem,
  classname,
  classnameButton,
}) => (
  <div className="product-list-desktop">
    <div className="ecommerce__container">
      {storeItem ? (
        storeItem.map((item) => (
          <a
            key={item.link[0].url}
            href={item.link[0].url}
            target="_blank"
            className={`ecommerce__link ${classname}`}
            rel="noreferrer"
          >
            <img
              src={item.image.fallbackUrl}
              alt={item.image.altText}
              className="ecommerce__image"
            />
          </a>
        ))
      ) : (
        <a
          href={buy.url}
          target="_blank"
          className={`py-2 ecommerce__button ${classnameButton}`}
          rel="noreferrer"
          aria-label={buy.name}
        >
          {buy.name}
        </a>
      )}
    </div>
  </div>
);

export default ProductWithLogo;
