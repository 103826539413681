import React, { FC, useRef } from 'react';

import { ImageAnimatedProps } from './models';

import './ImageAnimated.scss';

const ImageAnimated: FC<ImageAnimatedProps> = ({ image, alt }) => {
  const [isVisibleImage, setIsVisibleImage] = React.useState(false);
  const containerRef = useRef<HTMLHeadingElement>(null);

  const showImage = () => {
    setIsVisibleImage(true);
  };

  const hideImage = (e) => {
    setIsVisibleImage(false);
    e.stopPropagation();
  };

  return (
    <div
      className={isVisibleImage ? 'image-animated active' : 'image-animated normal'}
      onClick={() => showImage()}
      onKeyDown={() => showImage()}
      aria-hidden="true"
      ref={containerRef}
    >
      <button
        className={isVisibleImage ? 'image-animated__close' : 'd-none'}
        onClick={(e) => hideImage(e)}
        onKeyDown={(e) => hideImage(e)}
        type="button"
      >
        <></>
      </button>
      <img className="image-animated__img--animated" src={image} alt={alt} />
      <img className="image-animated__img" src={image} alt={alt} />
    </div>
  );
};

export default ImageAnimated;
