import React, { FC } from 'react';

import ImageAnimated from '../ImageAnimated/ImageAnimated';
import { ImageSliderProps } from './models';

import './ImageSlider.scss';

const ImageSlider: FC<ImageSliderProps> = ({
  title,
  imageDesktop1,
  imageDesktop2,
  imageDesktop3,
}) => (
  <div className="image-slider">
    <div className="image-slider__imgs">
      {imageDesktop1 ? (
        <img
          className="image-animated--dummy"
          src={imageDesktop1.fallbackUrl}
          alt={imageDesktop1.altText}
        />
      ) : null}
      {imageDesktop2 ? (
        <ImageAnimated image={imageDesktop2.fallbackUrl} alt={imageDesktop2.altText} />
      ) : null}
      {imageDesktop1 ? (
        <ImageAnimated image={imageDesktop1.fallbackUrl} alt={imageDesktop1.altText} />
      ) : null}
      {imageDesktop3 ? (
        <ImageAnimated image={imageDesktop3.fallbackUrl} alt={imageDesktop3.altText} />
      ) : null}
    </div>
    <h2 className="px-3">{title}</h2>
  </div>
);

export default ImageSlider;
